<template>
  <div class="business">
      <div class="volunteer-continar">
        <div class="binstitle">
        <span>规范性文件</span>
       </div>
       <div class="businesscontent">
           <Newslist 
           :list="bisinesslist" 
           :nodename="'xxpt_ywzs'" 
           @childByValue="childByValue"></Newslist>
       </div>
    </div>
  </div>
</template>

<script>
import apiUrls from '@api'
import Newslist from '@/components/newslist'
export default {
  components : {
    Newslist
  },
  name: 'Business',
  data() {
    return {
      bisinesslist: []
    }
  },
  created () {
    this.getbisiness()
  },
  methods: {
    getbisiness () {
      apiUrls.getlearnplat({node: 'xxpt_ywzs'}).then(res=>{
      this.bisinesslist= res.results.data
      })
    },
    childByValue (childByValue) {
      this.bisinesslist = childByValue
    }
  }
}
</script>

<style lang="less" scoped>
.business {
  background-color: #f5f5f5;
.volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
         .binstitle  {
          margin-top: 10px;
          font-size: 23px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          span {
              &:nth-child(1) {
              display: flex;
              &::before {
              content: "";
              display: block;
              height: 20px;
              width: 20px;
              background: url('../../../assets/img/title.png') no-repeat center center;
            }
          }
            &:nth-child(2) {
                color: #ABABAB;
                font-size: 17px;
            }
        }
   
      }
      .businesscontent {
        min-height: 571px;
        background-color: #ffffff;  
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        padding: 39px 63px 47px 40px;
      }
    }
  }
</style>
